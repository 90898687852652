import React, { useEffect, useState } from "react";
import PositionsSummary from "./PositionsSummary";
import NotificationLog from "./NotificationLog";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { useWebSocket } from "../../websocket/WebSocketProvider";
import { useNavigate } from "react-router-dom";
import Accounts from "./Accounts";
import Logo from "./Logo";
import { useCloseOrdersMutation } from "../../redux/closeOrdersApi";
import { handleToast } from "../utils/handleToast";
import { Box, Button, Pagination } from "@mui/material";

const DashboardForex = () => {
  const { socket, disconnectSocket } = useWebSocket();
  const [accounts, setAccounts] = useState([]);
  const [positionSummaryData, setPositionSummaryData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [notificationLoading, setNotificationsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [positionSummaryLoading, setPositionSummaryLoading] = useState(true);
  const [closeOrder, { isLoading }] = useCloseOrdersMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    if (socket) {
      // setAccountsLoading(true);
      // setNotificationsLoading(true);
      socket.emit("getAccountsDataFX", {
        page: currentPage,
        limit: pageSize,
      });
      socket.emit("riskNotificationLogs");
      socket.emit("stopAcctData", { type: "forex" });

      socket.on("accountsDataFX", (data) => {
        setAccounts(data.result);
        setTotalPages(data.totalPages);
        setAccountsLoading(false);
      });

      socket.on("riskNotificationLogsResult", (data) => {
        setNotificationsData(data);
        setNotificationsLoading(false);
      });

      socket.on("positionSummaryData", (data) => {
        setPositionSummaryData(data);
        setPositionSummaryLoading(false);
      });

      return () => {
        socket.off("accountsDataFX");
        socket.off("positionSummaryData");
      };
    }
  }, [socket, currentPage]);

  useEffect(() => {
    if (accounts.length > 0 && !selectedUserId) {
      const firstUserId = accounts[0].user_id;
      setSelectedUserId(firstUserId);
      setPositionSummaryLoading(true);
      if (socket) {
        socket.emit("getPositionSummary", {
          userId: firstUserId,
          type: "forex",
        });
      }
    } else if (!accounts.length) {
      if (socket) {
        socket.emit("getPositionSummary", {
          type: "forex",
        });
      }
    }
  }, [accounts, selectedUserId, socket]);

  const handleAccountClick = (data) => {
    if (!data) return;
    const { user_id: userId } = data;
    setSelectedUserId(userId);
    setPositionSummaryLoading(true);
    if (socket) {
      socket.emit("getPositionSummary", {
        userId,
        type: "forex",
      });
    }
  };

  // useEffect(() => {
  //   if (selectedUserId) {
  //     handleAccountClick(selectedUserId);
  //   }
  //   // setTimeout(() => {
  //   // }, [2000]);
  // }, [selectedUserId]);

  const handleCloseOrders = async (data) => {
    const { user_id } = data;
    const result = await closeOrder({ userId: user_id, type: "forex" });
    handleToast(result);
  };

  const handleLogout = () => {
    dispatch(logout());
    disconnectSocket();
    navigate("/", { replace: true });
  };

  return (
    <section className="dashboard_section">
      <div className="row m-0">
        <div className="col-md-4 p-0">
          <PositionsSummary
            isLoading={positionSummaryLoading}
            data={positionSummaryData}
          />
        </div>
        <div className="col-md-8 p-0">
          <div className="card ">
            <Logo handleLogout={handleLogout} />
            <Accounts
              isLoading={accountsLoading}
              data={accounts}
              onAccountClick={handleAccountClick}
              handleCloseOrders={handleCloseOrders}
            />
            {totalPages > 1 && (
              <div className="mb-2">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {/* <Button
                    variant="contained"
                    disabled={currentPage === 1}
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(1, prev - 1))
                    }
                    sx={{ marginRight: "10px" }}
                  >
                    Previous
                  </Button>

                  <div
                    className=" h6_hadding"
                    style={{ lineHeight: "36px", fontSize: "16px" }}
                  >
                    Page {currentPage} of {totalPages}
                  </div>

                  <Button
                    variant="contained"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    sx={{ marginLeft: "10px" }}
                    onLoad={accountsLoading}
                  >
                    Next
                  </Button> */}
                  <Pagination
                    count={totalPages}
                    onChange={() => setCurrentPage((prev) => prev + 1)}
                  />
                </Box>
              </div>
            )}
          </div>
          <div className="card ">
            <h6 className="mb-0 h6_hadding">Notification Log</h6>
            <NotificationLog
              isLoading={notificationLoading}
              data={notificationsData}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardForex;
